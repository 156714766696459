<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="filter">
        <!-- Name -->

        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("User Name") }}</label>
          <b-form-input
            v-model="filter.username"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("Ref") }}</label>
          <b-form-input
            v-model="filter.ref"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("Deposit Ref") }}</label>
          <b-form-input
            v-model="filter.depositRef"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Fromdate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-col>

        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Todate") }}</label>
          <flat-pickr
            id="todate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-col>
        <!-- action -->
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  props: {
    userNameFilter: {
      type: [String, null],
      default: null,
    },
    depositRefFilter: {
      type: [String, null],
      default: null,
    },

    refFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {},
  setup(props, { emit }) {
    const productIdOptions = ref();
    const flatpickrConfig = {
      dateFormat: "Y-m-d H:i:S", 
      enableTime: true, // Enable time selection
      time_24hr: true, // Use 24-hour format for time
    };
    const filter = ref({
      username: props.userNameFilter,
      ref: props.refFilter,
      depositRef: props.depositRefFilter,
      fromdate: props.fromdateFilter,
      todate: props.todateFilter,
    });
    const resetFilter = () => {
      emit("update:userNameFilter", null);
      emit("update:depositRefFilter", null);
      emit("update:refFilter", null);
      emit("update:fromdateFilter", null);
      emit("update:todateFilter", null);
      filter.value = {
        username: null,
        depositRef: null,
        ref: null,
        fromdate: null,
        todate: null,
      };
      emit("refetch-data");
    };

    const searchFilter = () => {
      emit("update:userNameFilter", filter.value.username);
      emit("update:depositRefFilter", filter.value.depositRef);
      emit("update:refFilter", filter.value.ref);
      // emit("update:ipFilter", filter.value.ip);
      emit("update:fromdateFilter", filter.value.fromdate);
      emit("update:todateFilter", filter.value.todate);
      emit("refetch-data");
      // emit("update:phoneFilter", filter.value.phone);
    };
    return {
      resetFilter,
      searchFilter,
      filter,flatpickrConfig
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
