<template>
  <div>
    <ManagerCashbackListFilters
      :user-name-filter.sync="userNameFilter"
      :ref-filter.sync="refFilter"
      :deposit-ref-filter.sync="depositRefFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      @refetch-data="refetchData"
    />
    <b-card no-body>
      <div class="p-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="$can('export', 'bonusdepositcashback')"
            class="col d-flex justify-content-end"
          >
            <b-button
              v-if="!showLoadingExport"
              variant="primary"
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled>
              <b-spinner small />
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                ref="exportButton"
                :data="data"
                :columns="columns"
                :file-name="exportFiletitle"
                :file-type="'xlsx'"
                :sheet-name="exportFileSheetName"
              >
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <div class="m-2">
				<b-row>
					<b-col
						cols="12"
						v-if="$can('create', 'bonuscode')"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddBonusModal"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> New
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div> -->
      <b-table
        ref="refBonusListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        hover
        head-variant="light"
        :no-border-collapse="true"
        :items="bonusCodeLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <b-link class="cursor-pointer">
            {{ data.item.user ? data.item.user.username : "" }}
          </b-link>
        </template>

        <template #cell(ref)="data">
          {{ data.item.ref }}
        </template>

        <template #cell(deposit_amount)="data">
          {{ numberFormat(data.item.deposit_amount / 1000) }}
        </template>

        <template #cell(amount)="data">
          {{ numberFormat(data.item.amount / 1000) }}
        </template>

        <template #cell(deposit_ref)="data">
          {{ data.item.deposit_ref }}
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBonus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import ManagerCashbackListFilters from "./ManagerCashbackListFilters.vue";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";

export default {
  components: {
    ManagerCashbackListFilters,
    VueExportExcel,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      roleId: null,
      content: null,
      bonusDetail: null,
      showLoadingExport: false,
      columns: [
        { label: "#", field: "id" },
        { label: "Ref", field: "ref" },
        { label: "Deposit Ref", field: "deposit_ref" },
        { label: "username", field: "user" },
        {
          label: "Amount",
          field: "amount",
          dataFormat: (value) => value,
        },
        {
          label: "Created At",
          field: "created_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        {
          label: "Updated At",
          field: "updated_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
      ],
      data: [],
      exportFiletitle: `Cashback-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "Manager Cashback",
      reference: null,
    };
  },
  mounted() {
    // this.bonusGroupList();
    // this.getCurrency();
    // this.getBonusCodeProduct();
    // this.bonusTypeList();
  },
  methods: {
    showAddBonusModal() {
      this.$bvModal.show("modal-add-bonus");
    },
    showEditBonusModal(datas) {
      this.bonusDetail = datas;
      this.$bvModal.show("modal-edit-bonus");
    },

    // bonusGroupList() {
    //   store
    //     .dispatch("bonus/fetchBonusGroups", {
    //       page: 1,
    //     })
    //     .then((response) => {
    //       const { bonusGroups } = response;
    //       this.groupNameOptions = bonusGroups;
    //     });
    // },
    // bonusTypeList() {
    //   store.dispatch("bonus/fetchBonusTypes").then((response) => {
    //     this.bonusTypeOptions = response.data.data;
    //   });
    // },
    // getCurrency() {
    //   store
    //     .dispatch("local-setting-currency/fetchCurrencies")
    //     .then((response) => {
    //       if (response.data.code == "00") {
    //         this.currencyOptions = response.data.data;
    //       }
    //     });
    // },
    // getBonusCodeProduct() {
    //   store.dispatch("bonus/fetchBonusCodeProduct").then((response) => {
    //     if (response.data.code == "00") {
    //       this.productOptions = response.data.data;
    //     }
    //   });
    // },
    async getExportData() {
      await store
        .dispatch("bonus/exportManagerCashback", {
          // page: pageNumber,
          deposit_ref: this.depositRefFilter,
          username: this.userNameFilter,
          ref: this.refFilter,
          fromDate: this.fromdateFilter,
          toDate: this.todateFilter,
          page: this.currentPage,
        })
        .then((response) => {
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    showUsername(value) {
      return value ? value.username : "123";
    },
    showName(value) {
      return value ? value.name : "";
    },
    async exportData() {
      this.showLoadingExport = true;
      await this.getExportData();
      await this.delay(2000);
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule("bonus"))
      store.registerModule("bonus", bonusStoreModule);
    const toast = useToast();
    const bonusLists = ref([]);
    const perPage = ref(25);
    const totalBonus = ref(0);
    const currentPage = ref(1);
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const refFilter = ref(null);
    const depositRefFilter = ref(null);
    const groupIdFilter = ref(null);
    const currencyFilter = ref(null);
    const userNameFilter = ref(null);
    const discountTypeFilter = ref(null);
    const statusFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const refBonusListTable = ref(null);
    const groupNameOptions = ref([]);
    const currencyOptions = ref([]);
    const productOptions = ref([]);
    const bonusCodeOptions = ref(null);
    const bonusTypeOptions = ref([]);
    const tableColumns = [
      { key: "index", label: "#" },
      //   "Username",
      { key: "username", label: "username" },
      { key: "ref" },
      "deposit_ref",
      "deposit_amount",
      "amount",
      "created_at",
      "updated_at",
    ];

    const refetchData = () => {
      refBonusListTable.value.refresh();
    };

    watch(
      [
        currentPage,
        refFilter,
        groupIdFilter,
        currencyFilter,
        userNameFilter,
        depositRefFilter,
        discountTypeFilter,
        statusFilter,
        fromdateFilter,
        todateFilter,
      ],
      () => {
        refetchData();
      }
    );

    const bonusCodeLists = (ctx, callback) => {
      store
        .dispatch("bonus/fetchManagerCashback", {
          deposit_ref: depositRefFilter.value,
          username: userNameFilter.value,
          ref: refFilter.value,
          currency: currencyFilter.value,
          //   amount: discountTypeFilter.value,
          // status: statusFilter.value,
          fromDate: fromdateFilter.value,
          toDate: todateFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          if (response.data.code == "00") {
            bonusCodeOptions.value = response.data.data;
            callback(response.data.data);
            totalBonus.value = response.data.count;
            perPage.value =
              currentPage.value == 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refBonusListTable.value
        ? refBonusListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalBonus.value,
      };
    });

    const typeOptions = [
      { label: "Cash", value: 1 },
      { label: "Percent", value: 2 },
    ];
    return {
      tableColumns,
      perPage,
      currentPage,
      totalBonus,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refBonusListTable,

      bonusLists,
      bonusCodeLists,
      refetchData,

      refFilter,
      groupIdFilter,
      groupNameOptions,
      currencyFilter,
      currencyOptions,
      userNameFilter,
      depositRefFilter,
      productOptions,
      discountTypeFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,

      // resolveStatus,
      // resolveBonusStatus,
      //   statusOptions,
      // resolveType,
      typeOptions,
      bonusCodeOptions,
      bonusTypeOptions,

      formatDateTime,
      numberFormat,
    };
  },
};
</script>
<style>
.b-table-sticky-column {
  right: 0 !important;
}
td.b-table-sticky-column {
  background-color: inherit;
}
</style>
